const initMetrics = () => {
    console.log('Load GTAG');
    window.dataLayer = window.dataLayer || [];
};

const trackEvent = (event) => {
    window.dataLayer.push(event);
};

const metricsService = {
    initMetrics,
    trackEvent,
};

export { metricsService };
