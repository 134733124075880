import { mParticleMetric } from '@/metrics';

const XSOLLA_CHECKOUT_VALUE = {
    DONE: 'done',
    PROCESSING: 'processing',
};

const XSOLLA_NOT_TRACKEABLE_EVENT = 'inspiha';

let isOrderCompleted = false;
let eventProperties = false;

const gtagTrackPurchase = (message) => {
    const { data } = JSON.parse(message);
    window.dataLayer.push({
        event: 'Converted to Pro',
        value: data.total_sum,
        currency: data.total_sum_currency,
        send_to: 'AW-659377836/d5cpCKXti-4BEKydtboC',
    });
    if (window.gtag) {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-659377836/d5cpCKXti-4BEKydtboC',
            'value': data.total_sum,
            'currency': data.total_sum_currency,
        });
    }
};

const trackPurchaseEvents = (message) => {
    const { data } = JSON.parse(message);
    const commonProperties = {
        ...eventProperties,
        purchase_id: data?.purchase_invoice_id,
        final_price: data?.total_sum,
        final_currency: data?.total_sum_currency,
        from_ad: false,
        reseller: 'xsolla',
    };
    mParticleMetric.track(mParticleMetric.EVENTS.ORDER_COMPLETED, commonProperties);
    mParticleMetric.track(mParticleMetric.EVENTS.CHECKOUT_COMPLETED, commonProperties);
    gtagTrackPurchase(message);
};

const messageHandler = (event) => {
    if (typeof event.data !== 'string') return;

    const { data: message } = event;

    if (!message || message === XSOLLA_NOT_TRACKEABLE_EVENT) return;

    const { command, data } = JSON.parse(message);

    if (!command) return;

    const { action, value } = data;

    if (!action) return;

    const parsedCommand = capitalizeWord(command.replace(/-/g, ' '));
    console.log('Xsolla ' + parsedCommand, data);
    mParticleMetric.track(`Xsolla ${parsedCommand}`, {
        ...eventProperties,
        ...data,
    });

    if (isOrderCompleted) return;

    if (isChangeStatus(command) && isDoneOrProcessing(value) ) {
        trackPurchaseEvents(message);
        isOrderCompleted = true;
    }
};

const capitalizeWord = (txt) => txt.replace(/\w\S*/g, (subtxt) => subtxt.charAt(0).toUpperCase() + subtxt.substr(1).toLowerCase());

const initialize = (properties) => {
    isOrderCompleted = false;
    eventProperties = properties;
    window.onmessage = messageHandler;
};

const isChangeStatus = (status) => status === 'change-status';
const isDoneOrProcessing = (value) => value === XSOLLA_CHECKOUT_VALUE.DONE || value === XSOLLA_CHECKOUT_VALUE.PROCESSING;

const XSollaWindowListener = {
    initialize,
    messageHandler,
};

export { XSollaWindowListener };