<template>
  <div>
    <loading-component v-if="loading" />
    <XSollaXPaymentComponent
      v-if="token && !loading"
      :token="token"
      :event-properties="commonEventProperties"
    />
    <PaddlePaymentComponentBilling
      v-if="paddleToken && !loading"
      :token="paddleToken"
      :email="storeUserInfo.email"
      :product-id="productId"
      :user-id="userId"
      :event-properties="commonEventProperties"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import { mParticleMetric } from '@/metrics';
import { CheckoutSession } from '@/services/checkout';
import { CookieService } from '@/services/cookie';
import { AccountClient } from '@/services/models/accounts';
import { AuthClient } from '@/services/models/authentication';
import { updateAuthToken } from '@/services/axios-client';
import { sessionServiceDomain } from '@/domain/session';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';

import {metricsService} from  '@/metrics/metrics';

export default {
    components:{
        LoadingComponent: () =>
            import(
                /* webpackChunkName: "LoadingComponent"*/ '@/components/LoadingComponent/LoadingComponent'
            ),
        XSollaXPaymentComponent: () => import(
            /* webpackChunkName: "StickerWithTextComponent" */ '@/components/XSollaXPaymentComponent/XSollaXPaymentComponent.vue'
        ),
        PaddlePaymentComponentBilling: () => import(
            /* webpackChunkName: "PaddlePaymentComponent" */ '@/components/PaddlePaymentComponent/PaddlePaymentComponentBilling.vue'
        ),
    },
    data() {
        return {
            userId: '',
            token: null,
            userInfo: null,
            loading: true,
            skus: null,
            productId: null,
            payload: null,
            gaClientIdWeb: '',
            client: null,
            isPaddle: false,
            paddleUrl: null,
            paddleToken: null,
        };
    },
    computed: {
        ...mapState({
            storeUserInfo: (state) => state.user_info_store.userInfo,
        }),
        isObjectPayload() {
            return (this.payload?.gaClientIdWeb === '' || this.payload?.gaClientIdWeb !== '');
        },
        commonEventProperties() {
            try {
                const checkoutSession = CheckoutSession.get();
                const userOS = this.$route.query.os || checkoutSession?.os;
                const decodedPayload = atob(this.payload);
                const newPayload = JSON.parse(decodedPayload);
                return {
                    product_id: newPayload.productId ?? null,
                    sku: newPayload.sku ?? null,
                    plan_id: newPayload.planId ?? null,
                    product_name: newPayload.productName ?? null,
                    price: newPayload.price ?? null,
                    currency: newPayload.currency ?? null,
                    original_voicemod_system: userOS === 'macOS' ? 'voicemod-v3-macos' : 'voicemod-v3-windows',
                };
            } catch (error) {
                Sentry.captureMessage('Unable to decode cehckout payload');
                return {};
            }
        },
    },
    async mounted() {
        metricsService.initMetrics();
        const checkoutSession = CheckoutSession.get();

        this.skus = this.$route.query.skus;
        this.productId = this.$route.query.productId;
        this.code = this.$route.query.code;
        this.payload = this.$route.query.payload;
        this.userOS = this.$route.query.os || checkoutSession?.os;
        this.client = this.$route.query.client || checkoutSession?.client;
        this.isPaddle = this.$route.query.paddle === 'true';

        this.userInfo = this.storeUserInfo;
        this.userId = this.getUserId();

        this.updatePurchaseItems();
        this.saveUserCheckoutSession();

        mParticleMetric.login({ customerid: this.userId });

        this.sendCheckoutLandedMetrics();

        if (this.hasNoValidSession()) {
            this.sendLandingNotAuthenticatedMetrics();
            this.redirectToLogin();
            return;
        }

        if (this.hasUserIdWithoutRefreshToken()) {
            await this.getRefreshTokenByCode();
        }

        if (!this.userInfo.userId) {
            await this.getUserInfo();
        }

        this.userId = this.userInfo.userId;

        mParticleMetric.login({ customerid: this.userId });

        this.sendCheckoutStartedMetrics();

        if (this.client && this.isPaddle) {
            await this.generatePaddleTokenForFlagship();
        }

        if (this.client && !this.isPaddle) {
            await this.generateXSollaPurchaseTokenForFlagship();
        }

        this.loading = false;
    },
    methods: {
        async generateXSollaPurchaseTokenForFlagship() {
            const items = this.getItems();
            this.gaClientIdWeb = this.getGoogleAnalyticsClientId();

            let newPayload = this.generatePayload();

            if (typeof newPayload === 'string') {
                const decodedPayload = atob(newPayload);
                newPayload = JSON.parse(decodedPayload);
            }

            try {
                const response = await AccountClient.generateXSollaUrlForFlagship(this.userId, this.userInfo, items, newPayload);
                const {data: xsollaInfo} = response;
                this.token = xsollaInfo.token;
            } catch (error) {
                const reseller = this.isPaddle ? 'paddle' : 'xsolla';
                Sentry.captureMessage('Can\'t generate XSolla Token for Flagship');
                Sentry.captureException(error);
                mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_CHECKCOUT_ERROR, {
                    ...this.commonEventProperties,
                    checkout_error_reason: 'xsolla_token_failure_flagship',
                    checkout_error_message: error?.response?.data?.message ? error.response.data.message : 'Error',
                    reseller,
                });
                this.$router.push(ROUTER_PATHS.PAYMENT_ERROR);
            }
        },
        async generatePaddleTokenForFlagship() {
            const items = this.getItems();
            this.gaClientIdWeb = this.getGoogleAnalyticsClientId();

            let newPayload = this.generatePayload();

            if (typeof newPayload === 'string') {
                const decodedPayload = atob(newPayload);
                newPayload = JSON.parse(decodedPayload);
            }

            try {
                const response = await AccountClient.generatePaddleTokenForFlagship(this.userId, this.userInfo, items, newPayload);
                const {data: paddleInfo} = response;
                this.paddleToken = paddleInfo.token;
            } catch (error) {
                const reseller = this.isPaddle ? 'paddle' : 'xsolla';
                Sentry.captureMessage('Can\'t generate Paddle Token for Flagship');
                Sentry.captureException(error);
                mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_CHECKCOUT_ERROR, {
                    ...this.commonEventProperties,
                    checkout_error_reason: 'paddle_token_failure_flagship',
                    checkout_error_message: error?.response?.data?.message ? error.response.data.message : 'Error',
                    reseller,
                });
                this.$router.push(ROUTER_PATHS.PAYMENT_ERROR);
            }
        },
        generatePayload() {
            if (this.isObjectPayload) {
                return this.payload;
            }

            const decodedPayload = atob(this.payload);
            return {
                ...JSON.parse(decodedPayload),
                gaClientIdWeb: this.gaClientIdWeb,
            };
        },
        async getUserInfo() {
            const sessionUserInfo = JSON.parse(sessionStorage.getItem('userInfo'));

            if (sessionUserInfo) {
                this.setUserInfo(sessionUserInfo);
                return;
            }

            const { data } = await AccountClient.getUserInfo(this.userId);
            this.setUserInfo(data);
        },
        getGoogleAnalyticsClientId() {
            try {
                if (window.ga && window.ga.getAll) {
                    return window.ga.getAll()[0].get('clientId');
                }

                return '';
            } catch (error) {
                Sentry.captureMessage('Can\'t retrieve GA clientId');
                Sentry.captureException(error);
                return '';
            }
        },
        updatePurchaseItems() {
            const checkoutSession = CheckoutSession.get();
            if (!this.skus && checkoutSession?.skus) {
                this.skus = checkoutSession.skus.join(',');
            }

            if (!this.productId && checkoutSession?.productId) {
                this.productId = checkoutSession.productId;
            }

            if (this.code && checkoutSession?.payload) {
                this.payload = checkoutSession.payload;
            }

            if (!this.giftingEmail && checkoutSession?.giftingEmail) {
                this.giftingEmail = checkoutSession.giftingEmail;
            }

            if (!this.isBeg && checkoutSession?.isBeg) {
                this.isBeg = checkoutSession.isBeg;
            }

            if (!this.client && checkoutSession?.client) {
                this.client = checkoutSession.client;
            }

            if (!this.paddleUrl && checkoutSession?.paddleUrl) {
                this.paddleUrl = checkoutSession.paddleUrl;
            }

            if (!this.isPaddle && checkoutSession?.isPaddle) {
                this.isPaddle = checkoutSession.isPaddle;
            }
        },
        hasNoValidSession() {
            return !CookieService.getCookie('refresh_token') && !this.userId;
        },
        hasUserIdWithoutRefreshToken() {
            return !CookieService.getCookie('refresh_token') && this.userId;
        },
        redirectToLogin() {
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.CHECKOUT,
                    extraData: {
                        client: this.client,
                    },
                },
            });
        },
        setUserInfo(data) {
            this.$store.dispatch('user_info_store/axn_setUserInfo', {userId: this.userId, ...data});
            sessionStorage.setItem('userInfo', JSON.stringify({userId: this.userId, ...data}));
            this.userInfo = {userId: this.userId, ...data};
        },
        getRefreshTokenByCode() {
            if (!this.code) { return; }

            return this.getAccessToken(this.code);
        },
        getItems() {
            if (this.skus) {
                return this.skus.split(',').map((sku) => ({ id: sku, qty: 1}));
            }

            if (this.client) {
                return this.productId;
            }

            return { id: this.productId, qty: 1 };
        },
        getUserId() {
            const userIdCookie = CookieService.getCookie('userId');
            const userIdQueryParam = this.$route.query.userId;

            if (userIdCookie) {
                return userIdCookie;
            }
            if (userIdQueryParam) {
                return userIdQueryParam;
            }

            return null;
        },
        saveUserCheckoutSession() {
            this.gaClientIdWeb = this.getGoogleAnalyticsClientId();

            const checkoutInfo = {
                userId: this.userId || null,
                token: this.token || null,
                ...(this.skus && { skus: this.skus.split(',') }),
                ...(this.productId && { productId: this.productId }),
                payload: this.generatePayload(),
                os: this.userOS || null,
                ...(this.client && { client: this.client }),
                ...(this.isPaddle && { isPaddle: this.isPaddle }),
            };

            if (checkoutInfo.giftingEmail || checkoutInfo.beg) {
                checkoutInfo.giftingEmail = null;
                checkoutInfo.isBeg = null;
            }

            if (this.giftingEmail && this.isBeg) {
                checkoutInfo.giftingEmail = this.giftingEmail;
                checkoutInfo.isBeg = this.isBeg;
            }

            CheckoutSession.save(checkoutInfo);
        },
        async getAccessToken() {
            try {
                const { data } = await AuthClient.getAccessToken(this.code);
                if (data.access_token && data.refresh_token) {
                    sessionServiceDomain.setLoginCookies(data);

                    updateAuthToken(data.access_token);
                }
            } catch (error) {
                sessionServiceDomain.deleteLoginCookies();
                mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_UNEXPECTED_ERROR_OCCURRED, {
                    ...this.commonEventProperties,
                    login_error_reason: 'checkout_failed_access_token',
                });
                this.$router.push({
                    path: ROUTER_PATHS.ACCOUNT_ACTIVATED_ERROR,
                    query: {
                        action: LOGIN_ACTIONS_CONSTANTS.CHECKOUT,
                    },
                });
            }
        },
        sendCheckoutStartedMetrics() {
            const reseller = this.isPaddle ? 'paddle' : 'xsolla';
            mParticleMetric.track(mParticleMetric.EVENTS.CHECKOUT_STARTED, {
                ...this.commonEventProperties,
                from_ad: false,
                reseller,
            });
            window.dataLayer.push({event: 'checkoutStarted'});
        },
        sendCheckoutLandedMetrics() {
            const reseller = this.isPaddle ? 'paddle' : 'xsolla';
            mParticleMetric.track(mParticleMetric.EVENTS.CHECKOUT_LANDING, {
                ...this.commonEventProperties,
                from_ad: false,
                reseller,
            });
            window.dataLayer.push({event: 'checkoutLanding'});
        },
        sendLandingNotAuthenticatedMetrics() {
            const reseller = this.isPaddle ? 'paddle' : 'xsolla';
            mParticleMetric.track(mParticleMetric.EVENTS.CHECKOUT_LANDING_NOT_AUTHENTICATED, {
                ...this.commonEventProperties,
                from_ad: false,
                reseller,
            });
            window.dataLayer.push({event: 'checkoutLandingNotAuthenticated'});
        },
    },
};
</script>