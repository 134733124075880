const save = (checkoutData) => {
    const parsedCheckoutData = JSON.stringify(checkoutData);

    sessionStorage.setItem('checkout', parsedCheckoutData);
};

const get = () => {
    const checkoutData = sessionStorage.getItem('checkout');

    return JSON.parse(checkoutData);
};


const CheckoutSession = {
    save,
    get,
};

export { CheckoutSession };